<template>
  <v-main class="m-16 px-8 prosker-container">
    <v-row class="mx-md-16 d-flex flex-column">
      <v-col class="d-flex align-center justify-center">
        <hooper
          ref="hooperSlider"
          :itemsToShow="itemsToShow"
          :infiniteScroll="true"
          :autoplay="true"
          :autoplaySpeed="3000"
          :loop="loop"
          :dots="true"
          :wheelControl="wheelControl"
          :touchDrag="touchDrag"
          :mouseDrag="mouseDrag"
          :centerMode="true"
          :wheelSensitivity="0.05"
          class="custom-hooper"
        >
          <slide v-for="(item, n) in slides" :key="n">
            <div class="slide-content">
              <div class="container-image">
                <div class="overlay-content d-flex align-center justify-center v-card v-sheet theme--light">
                    <p class="m-0 title_highlight_color--text font-weight-bold">{{ item.subtitle }}</p>
                </div>
                <v-img :src="item.image" class="slide-image" contain></v-img>
              </div>
              <div class="slide-text text-md-left text-lg-left">
                <p class="font-weight-bold text-h5 title_highlight_color--text">{{ item.title }}</p>
                <p>{{ item.content }}</p>
                <div class="custom-nav-buttons d-flex">
                  <v-img
                    :src="prevArrow"
                    class="custom-arrow"
                    @click="slidePrev"
                    contain
                  ></v-img>
                  <v-img
                    :src="nextArrow"
                    class="custom-arrow"
                    @click="slideNext"
                    contain
                  ></v-img>
                </div>
              </div>
            </div>
          </slide>
          <hooper-pagination slot="hooper-addons" ></hooper-pagination>
        </hooper>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { Hooper, Slide,
  Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'HomeProskerSlider',
  components: {
    Hooper,
    Slide,
    HooperPagination
  },
  data () {
    return {
      slides: [
        {
          image: require('@/assets/images/page-img/bannerA.png'),
          title: this.$t('publicProfile'),
          content: this.$t('profileShowcase'),
          subtitle: this.$t('visibility')
        },
        {
          image: require('@/assets/images/page-img/FrameCatalogo.png'),
          title: this.$t('standardizedCatalog'),
          content: this.$t('servicesWithAgenda'),
          subtitle: this.$t('practicality')
        },
        {
          image: require('@/assets/images/page-img/slider3.png'),
          title: this.$t('customServices'),
          content: this.$t('personalizedOffers'),
          subtitle: this.$t('flexibility')
        },
        {
          image: require('@/assets/images/page-img/slider4.png'),
          title: this.$t('automatedAgenda'),
          content: this.$t('selectDayAndHours'),
          subtitle: this.$t('efficiency')
        },
        {
          image: require('@/assets/images/page-img/slider5.png'),
          title: this.$t('electronicppPayments'),
          content: this.$t('mercadoPagoPayments'),
          subtitle: this.$t('security')
        }
      ],
      itemsToShow: 1,
      prevArrow: require('@/assets/images/page-img/arrow_downward-back.png'),
      nextArrow: require('@/assets/images/page-img/arrow_downward.png'),
      loop: true,
      wheelControl: false,
      touchDrag: true,
      mouseDrag: true,
      pagination: true
    };
  },
  methods: {
    slidePrev () {
      this.$refs.hooperSlider.slidePrev();
    },
    slideNext () {
      this.$refs.hooperSlider.slideNext();
    }
  }
};
</script>

<style scoped>
.custom-hooper :deep(.hooper-track) {
  padding-left: 0;
}
:deep(.hooper-indicators .hooper-indicator) {
  width: 27px !important;
}
.prosker-container {
  background: #F7F9FF;
  border-radius:32px;
}
.slide-content {
  background: #F7F9FF;
}
.custom-nav-buttons {
  height:50px !important;
  width: min-content;
}
.custom-hooper .hooper-slide {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2%;
  padding-bottom: 2%;
}
.custom-hooper {
  height:auto;
  margin-top:5px;
}

.slide-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width:100%;
}

.container-image {
  width: 70%;
  position: relative;
  padding-top: 10px;
  padding: 0px;

}
.slide-image {
  width: auto%;
  position: relative;
  padding: 60px !important
}
.overlay-content {
  width: 250px;
  height : 55px;
  border-radius:10px;
  margin: auto;
}
.slide-text {
  padding: 20px;
  max-width: 30%;
}

@media (max-width: 768px) {

  .slide-content {
    flex-direction: column;
  }
  .slide-image, .container-image {
    width: 100%;
  }

  .slide-text {
    padding: 10px;
    max-width: 100%;
  }
  .overlay-content, .custom-arrow {
    display:none !important;
  }
}
</style>
